<template>
  <div>
    <div class="subheader mb-2">{{ label }}</div>
    <b-form-datepicker
      v-model="computedSetDate"
      class="mb-2"
      :format="'YYYY-MM-DD'"
      :state="isValid"
      @input="dateSelected($event)"
      :required="required"
    >
    </b-form-datepicker>
  </div>
</template>

<script>
export default {
  name: "DateSelect",
  data: () => {
    return {
      selected: "",
      random: "",
    };
  },
  props: {
    label: { default: "Choose A Date" },
    setdate: { default: "" },
    errormessage: { default: "Please enter a date" },
    required: { default: false },
  },
  methods: {
    dateSelected(event) {
      this.selected = event;
      this.$emit("update:selected", this.selected);
    },
  },
  computed: {
    computedSetDate: {
      get() {
        return this.setdate;
      },
      set(value) {
        this.selected = value;
      },
    },
    isValid() {
      if (this.required) {
        return this.selected.length > 0 || this.setdate.length > 0;
      } else {
        return true;
      }
    },
  },
};
</script>
